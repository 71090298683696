import { render, staticRenderFns } from "./Leaflet.vue?vue&type=template&id=4d895e50&scoped=true&"
import script from "./Leaflet.vue?vue&type=script&lang=js&"
export * from "./Leaflet.vue?vue&type=script&lang=js&"
import style0 from "./Leaflet.vue?vue&type=style&index=0&id=4d895e50&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d895e50",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
